<template>
  <LayoutVendor>
    <AppLoading v-if="!company || !userGroups"></AppLoading>

    <div v-else class="add-user-container mt-md-5 m-auto d-block">
      <AppCard>
        <div class="p-2 d-flex align-items-center justify-content-between">
          <h4>ADD USER</h4>
        </div>
        <div class="p-2 d-flex align-items-center">
          <div>
            <AppMediaViewer width="40px"></AppMediaViewer>
          </div>
          <span class="ml-2 font-weight-6">
            {{ company.name }}
          </span>
        </div>
      </AppCard>

      <AppCard class="mt-2 p-3">
        <h4>USER INFORMATION</h4>
        <AppInputForm
          v-model="userAdd.formValid"
          v-slot="{ validate }"
          @validate="addUser"
        >
          <div class="d-flex">
            <AppInput
              v-model="userAdd.form.first_name"
              label="First Name"
              class="w-50"
              :validations="[validation.required()]"
              :validate="validate"
            ></AppInput>
            <AppInput
              v-model="userAdd.form.last_name"
              label="Last Name"
              class="w-50 ml-2"
              :validations="[validation.required()]"
              :validate="validate"
            ></AppInput>
          </div>
          <div>
            <AppInputSelect
              v-model="userAdd.form.groups"
              multiple
              label="Role"
              class="w-199"
              :items="userGroups"
              :validations="[validation.required()]"
              :validate="validate"
            ></AppInputSelect>
          </div>
          <div class="mt-3">
            <h4>LOG IN CREDENTIALS</h4>
            <AppInput
              v-model="userAdd.form.email"
              label="Email Address"
              placeholder="Email Address"
              :validations="[validation.required(), validation.email()]"
              :validate="validate"
            ></AppInput>
            <!-- Contact Number -->
            <AppInput
              v-model="userAdd.form.mobile_number"
              label="Contact Number"
              placeholder="Contact Number"
              :validations="[validation.required(), validation.minLength(8)]"
              :validate="validate"
            ></AppInput>
            <!-- Password -->
            <AppInput
              v-model="userAdd.form.password"
              label="Password"
              type="password"
              :validations="[validation.required()]"
              :validate="validate"
            ></AppInput>
          </div>
          <div
            class="
              d-flex
              flex-column flex-md-row
              justify-content-center justify-content-md-end
            "
          >
            <!-- <AppBtn outline :loading="loading" @click="userAdd.modal = false">
              Cancel
            </AppBtn> -->
            <AppBtn
              type="submit"
              class="mt-2 mt-md-0 ml-md-2"
              :loading="loading"
            >
              Add User
            </AppBtn>
          </div>
        </AppInputForm>
      </AppCard>
    </div>

    <!-- Confirm Add Modal -->
    <ActionModal v-model="userAdd.confirm" @confirm="addUser">
      <template v-slot:title> Add User </template>
      <template v-slot:description> Click confirm to proceed </template>
    </ActionModal>
    <!-- Success Add Modal -->
    <SuccessModal
      v-model="userAdd.success"
      @confirm="$router.push({ name: 'MemberList' })"
    >
      <template v-slot:title> User Created </template>
      <template v-slot:description> New member created </template>
      <template v-slot:action-btn-text> Close </template>
    </SuccessModal>
  </LayoutVendor>
</template>

<script>
import AppCard from '@/shared/elements/AppCard.vue';
import AppMediaViewer from '@/shared/elements/AppMediaViewer.vue';
import AppInputForm from '@/shared/elements/AppInputForm.vue';
import AppInput from '@/shared/elements/AppInput.vue';
import AppInputSelect from '@/shared/elements/AppInputSelect.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import PersonaConstants from '@/shared/constants/PersonaConstants';
import { group_names } from '@/shared/constants/PersonaConstants';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import validation from '@/shared/utils/validation';
import { isEqual } from 'lodash';
import Company from '@/shared/api/Company';
import User from '@/shared/api/Users';
import ActionModal from '@/components/Partial/Modals/ActionModal.vue';
import SuccessModal from '@/components/Partial/Modals/SuccessModal.vue';
import AppLoading from '@/shared/elements/AppLoading.vue';
import LayoutVendor from '@/components/Partial/Layouts/LayoutVendor/LayoutVendor.vue';

const INITIAL_FORM_STATE = {
  first_name: '',
  last_name: '',
  groups: [],
  email: '',
  password: '',
  mobile_number: '',
  get_marketing_update_email: false,
  address: 'N/A',
  city: 'N/A',
  state: 'N/A',
  zip_code: 'N/A',
};

export default {
  name: 'VendorMemberAdd',

  components: {
    AppCard,
    AppMediaViewer,
    AppInputForm,
    AppInput,
    AppInputSelect,
    AppBtn,
    LayoutVendor,
    ActionModal,
    SuccessModal,
    AppLoading,
  },

  mixins: [_appApiHelper],

  data() {
    return {
      validation,
      id: this.$store.getters['auth/user'].company_id,
      company: null,
      currentTab: 0,
      userGroups: [],
      userAdd: {
        modal: false,
        success: false,
        confirm: false,
        formValid: false,
        form: { ...INITIAL_FORM_STATE },
      },
    };
  },

  watch: {
    userAdd: {
      handler(userAdd) {
        const userGroups = this.userGroups;
        if (userGroups.length === 0 || userAdd.form.groups.length === 0) {
          return;
        }

        const adminChosen = userAdd.form.groups.find((group_id) => {
          const groupMatch = userGroups.find(
            (group) => group.text === group_names.admin.name
          );
          return groupMatch.value === group_id;
        });

        if (
          adminChosen &&
          !isEqual(
            userAdd.form.groups,
            userGroups.map((group) => group.value)
          )
        ) {
          this.userAdd.form.groups = userGroups.map((group) => group.value);
        }
      },
      deep: true,
    },
  },

  methods: {
    async fetchCompanyDetails() {
      const response = await Company.getCompany(this.id);
      this.company = response.data;
      const users = this.company.users;
      if (users.length > 0) {
        const ownerConst = group_names.owner;
        const owner = users.find((user) => user.persona === ownerConst.name);
        if (owner) {
          this.company.owner = owner;
        } else {
          this.company.owner = users[0];
        }
      }
    },
    async fetchUserGroup() {
      const companyType = this.company.owner.persona;
      const { group_names } = PersonaConstants[companyType];
      const owner = group_names.find((group) => group.value === 'owner');
      const response = await User.getUserGroups();
      this.userGroups = response.data
        .filter(
          (group) =>
            group.name !== owner.name &&
            group_names.find((constGroup) => constGroup.name === group.name)
        )
        .map((group) => ({
          text: group.name,
          value: group.id,
        }));
    },
    async addUser() {
      if (!this.userAdd.formValid) {
        return;
      }

      if (!this.userAdd.confirm) {
        return (this.userAdd.confirm = true);
      }

      const form = {
        ...this.userAdd.form,
        company_id: this.company.id,
        company_name: this.company.name,
        company: this.company.name,
        persona: this.company.owner.persona,
        password_confirmation: this.userAdd.form.password,
      };
      this.$store.dispatch('api/preventSuccessModal');
      const response = await User.postUser(form);
      if (response.status === 200) {
        this.company.users.push(response.data);
        this.resetForm();
        this.$store.dispatch('auth/refreshSelf');
        this.userAdd.success = true;
        this.userAdd.modal = false;
      } else {
        this.userAdd.error = this.error;
      }
      // add user here
    },
    resetForm() {
      this.userAdd.form = { ...INITIAL_FORM_STATE };
    },
  },

  async mounted() {
    await this.fetchCompanyDetails();
    await this.fetchUserGroup();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_inputs';

.add-user {
  &-container {
    max-width: 1000px;
    &-indicator {
      max-width: 500px;
    }
  }
}

.add-photo {
  cursor: pointer;
  &-text {
    display: none;
    opacity: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }
  &-placeholder {
    width: 70px;
    height: 70px;
  }
  &:hover {
    .add-photo-text {
      display: block;
      opacity: 1;
    }
  }
}
</style>
